<template>
  <div>
    <video
      preload="auto"
      poster="@/assets/login/Snapshot-min.png"
      src="https://desarrollo.factoriamedia.com/videos_tour/video_1_loop.mp4"
      loop
      autoplay
      muted
      class="fondo_login"
    ></video>
    <div class="overlay"></div>
    <div class="login" id="resize">
      <img src="@/assets/login/logoCol.svg" class="logo" alt="" />
      <img src="@/assets/login/log_tit.svg" class="logoTit" alt="" />
      <el-row class="main">
        <el-col :span="12" class="bienvenida">
          <!-- <h1>Visitas<br />a PLANTA</h1>
          <p v-for="usuario in usuarios" :key="usuario.id">
          {{ usuario.nombre }} - {{ usuario.event }}
        </p> -->
        </el-col>
        <el-col :span="12" class="formulario">
          <el-form v-loading="loading">
            <span>Ingresar</span>
            <el-input v-model="user" placeholder="Usuario">
              <i class="el-icon-user-solid el-input__icon prefix" slot="prefix">
              </i>
            </el-input>
            <el-input
              v-model="password"
              placeholder="Contraseña"
              type="password"
            >
              <i class="el-icon-lock el-input__icon prefix" slot="prefix"> </i>
            </el-input>
            <a @click="forgotPassword">¿OlVIDASTE TU CONTRASEÑA?</a>
            <el-button @click="login">LOG IN</el-button>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";

var realtimeDB = firebase.database();

import router from "../router";

export default {
  data() {
    return {
      usuarios: [],
      user: "",
      password: "",
      loading: false,
    };
  },
  firebase: {
    usuarios: realtimeDB.ref("users"),
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onWindowResize, false);
      this.onWindowResize();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize, false);
  },
  methods: {
    onWindowResize() {
      var element = document.querySelector(".login");
      var windowWidth = window.innerWidth;
      var windowHeight = window.innerHeight;
      var width = 1920;
      var height = 1080;

      var scale = Math.min(windowWidth / width, windowHeight / height);

      var offsetX = Math.floor((windowWidth - width) / 2);
      var offsetY = Math.floor((windowHeight - height) / 2);

      element.style.left = offsetX + "px";
      element.style.top = offsetY + "px";
      element.style.transform = "scale(" + scale + ")";
    },
    login() {
      this.loading = true;
      const auth = firebase.auth();
      auth
        .signInWithEmailAndPassword(this.user, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          this.loading = false;
          router.push("/presentacion");
          var elem = document.querySelector("html");
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$notify({
            title: "¡Error!",
            message: error.message,
            type: "error",
          });
        });
    },
    forgotPassword() {
      const auth = firebase.auth();
      auth
        .sendPasswordResetEmail(this.user)
        .then(() => {
          this.$notify({
            title: "¡Listo!",
            message: "Te hemos enviado un correo para recuperar tu contraseña",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            title: "¡Error!",
            message: error.message,
            type: "error",
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "../vendor/reveal/css/reveal.scss";
@import "../vendor/reveal/css/theme/black.css";

#container-presentacion {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

.el-notification__title {
  font-family: "VAG Rounded Std";
}
.el-notification__content p {
  font-family: "VAG Rounded Std";
  text-align: left;
}

.reveal {
  height: 100vh;
  section,
  #background {
    img,
    video {
      max-width: unset;
      max-height: unset;
      margin: 0px;
      border: unset;
      box-shadow: unset;
      background: unset;
    }

    top: 0 !important;
  }
}

.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  // z-index: 1;
  background-image: url("../assets/login/pattern.png");
  // background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.fondo_login {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  z-index: -1;
}

.login {
  width: 1920px;
  height: 1080px;
  position: absolute;

  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
    width: 207px;
    height: 134px;
  }
  .logoTit {
    position: absolute;
    left: 205px;
    top: 478px;
    width: 681px;
    height: 242px;
  }
  .main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 365px;
    .bienvenida {
      color: white;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 0 200px;
      h1 {
        font-size: 70px;
        margin: 0;
        line-height: 0.9;
        margin-bottom: 20px;
        font-family: "VAG Rounded Std";
      }
      p {
        margin: 5px;
        font-family: "VAG Rounded Std";
        font-size: 16px;
      }
    }
    .formulario {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-family: "Montserrat";
      color: white;
      form {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 25px;
        width: 460px;
        height: 380px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 85px;
        box-sizing: border-box;
        span {
          font-weight: bold;
          font-size: 18px;
        }
        input {
          margin: 10px;
          background-color: rgba(255, 255, 255, 0.25);
          border: none;
          border-radius: 25px;
          color: white;
          padding-left: 60px;
        }
        .el-input__prefix {
          left: 12px;
          top: 10px;
          background-color: white;
          border-radius: 100%;
          aspect-ratio: 1;
          width: 40px;
          height: 40px;
        }
        a {
          display: block;
          color: white;
          text-align: right;
          font-size: 10px;
          align-self: end;
          text-decoration: none;
          cursor: pointer;
        }
        button {
          margin-top: 20px;
          background-color: #e41e2a;
          border-radius: 25px;
          border: none;
          color: white;
          padding: 15px 40px;
          box-shadow: 0px 5px 0px #84181c;
          transition: box-shadow 200ms ease-out;
        }
        button:active {
          box-shadow: 0px 0px 0px #84181c;
        }
        .el-loading-mask {
          background-color: rgba(0, 0, 0, 0) !important;
        }

        .el-loading-spinner {
          top: 90% !important;
        }
        .el-loading-spinner .path {
          stroke: #ffffff;
        }
      }
    }
  }
}
</style>
